/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ModalDataLimit.vue?vue&type=template&id=f75a4678&scoped=true&"
import script from "./ModalDataLimit.vue?vue&type=script&lang=js&"
export * from "./ModalDataLimit.vue?vue&type=script&lang=js&"
import style0 from "./ModalDataLimit.vue?vue&type=style&index=0&id=f75a4678&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f75a4678",
  null
  
)

export default component.exports